import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from './components/MainLayout'
import AboutUs from './page/AboutUs'
import Collab from './page/Collab'
import Home from './page/Home'
// import Maintenance from './page/Maintenance'
import OurBox from './page/OurBox'
import OurLocation from './page/OurLocation'


const App = () => {
  return (
    <Routes>
      {/* <Route path='/' element={<Maintenance />} /> */}
      <Route path='/' element={<Home />} />
      <Route element={<MainLayout />} >
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/our-location' element={<OurLocation />} />
        <Route path='/our-box' element={<OurBox />} />
        <Route path='/collab-with-us' element={<Collab />} />
      </Route>
    </Routes>
  )
}

export default App
