import React, { useEffect, useState } from "react";
import Card from "../components/common/card";
import axios from "axios";
import Rent from "../assets/rent.png";
import { ReactComponent as Dice } from "../assets/Dice.svg";
import { BASE_URL } from "../api/BaseUrl";
import Api from "../api/Api";

const Collab = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [idKota, setIdKota] = useState("")
  const [idProvinsi, setIdProvinsi] = useState("");
  const [provinsi, setProvinsi] = useState([]);
  const [kota, setKota] = useState([]);
  const [kecamatan, setKecamatan] = useState([])
  const getprovinsi = provinsi.provinsi;
  const getkota = kota.kota_kabupaten;
  const getKecamatan = kecamatan.kecamatan;


  //form data
  // picinformation
  const [picName, setPicName] = useState("");
  const [mobile, setMobile] = useState("");
  const [brandName, setBrandName] = useState("");
  const [email, setEmail] = useState("");

  //pic address
  const [address, setAddress] = useState("")
  const [selectKota, setSelectKota] = useState("");
  const [selectProvinsi, setSelectProvinsi] = useState("");
  const [selectKecamatan, setSelectKecamatan] = useState("")
  const [detailKota, setDetailKota] = useState('')
  const [detailProvinsi, setDetailProvinsi] = useState('')
  const [postalcode, setPostalcode] = useState('')

  const [category, setCategory] = useState('')

  const [detail, setDetail] = useState('')

  const [imageSelected, setImageSelected] = useState({});
  console.log(imageSelected);

  useEffect(() => {
    const fetchData = async () => {
      const res = await Api.get('main-content')
      setData(res.data.data)
    }
    fetchData()
  }, [])



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (imageSelected === '') {
      try {
        setLoading(true)
        await axios.post(`${BASE_URL}/send-email-without-attach`, {
          picName: picName,
          brandName: brandName,
          mobile: mobile,
          email: email,
          address: address,
          detailProvinsi: detailProvinsi,
          detailKota: detailKota,
          postalcode: postalcode,
          category: category,
          detail: detail,
          selectKecamatan: selectKecamatan
        })

        setPicName("")
        setMobile("")
        setBrandName("")
        setEmail("")

        setAddress("")
        setSelectKota("")
        setSelectProvinsi("")
        setSelectKecamatan("")
        setDetailKota("")
        setDetailProvinsi("")
        setPostalcode("")
        setCategory("")
        setDetail('')
        setImageSelected('')

        setLoading(false)
        alert('email terkirim!')
      } catch (err) {
        setLoading(false)
        alert(err.message)
      }
    } else {
      try {
        setLoading(true)
        const formData = new FormData();
        formData.append("asset", imageSelected);
        formData.append("picName", picName);
        formData.append("brandName", brandName);
        formData.append("mobile", mobile);
        formData.append("email", email);
        formData.append("address", address);
        formData.append("detailProvinsi", detailProvinsi);
        formData.append("detailKota", detailKota);
        formData.append("postalcode", postalcode);
        formData.append("category", category);
        formData.append("description", detail);
        formData.append("selectKecamatan", selectKecamatan);

        await axios.post(`${BASE_URL}/send-email`, formData)

        setPicName("")
        setMobile("")
        setBrandName("")
        setEmail("")

        setAddress("")
        setSelectKota("")
        setSelectProvinsi("")
        setSelectKecamatan("")
        setDetailKota("")
        setDetailProvinsi("")
        setPostalcode("")
        setCategory("")
        setDetail('')
        setImageSelected({})

        setLoading(false)
        alert('email terkirim!')
      } catch (err) {
        setLoading(false)
        alert(err.message)
      }
    }


  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        "https://dev.farizdotid.com/api/daerahindonesia/provinsi"
      );
      const resKota = await axios.get(
        `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${idProvinsi}`
      );
      const resKecamatan = await axios.get(
        `https://dev.farizdotid.com/api/daerahindonesia/kecamatan?id_kota=${idKota}`
      )

      setProvinsi(res.data);
      setKota(resKota.data);
      setKecamatan(resKecamatan.data)
    };
    const detail = async () => {
      const resDetailProv = await axios.get(`https://dev.farizdotid.com/api/daerahindonesia/provinsi/${idProvinsi}`)
      const resDetailKota = await axios.get(`https://dev.farizdotid.com/api/daerahindonesia/kota/${idKota}`)
      setDetailKota(resDetailKota.data.nama)
      setDetailProvinsi(resDetailProv.data.nama)
    }
    fetch();
    detail()
  }, [setProvinsi, setKota, idProvinsi, idKota]);

  const dataInfluencer = data.filter((item) => item.id_category.category === 'contact')
  return (
    <>
      <h1 className="text-center mb-2 font-bold text-[30px] pt-[100px]">
        COLLAB WITH US
      </h1>
      <div className="flex flex-col justify-center p-6 lg:flex-row">
        <Card
          styleCard='p-6  lg:w-[500px] lg:h-[300px] transition-all duration-400 ease-in-out bg-white rounded-lg border border-gray-200 shadow-md m-10'
        >
          <div className="flex flex-col justify-evenly">
            <div className="flex justify-center items-center mb-4 ">
              <h1 className="font-bold text-[20px]">Machine Rent</h1>
            </div>
            <div
              className="mb-3 transition-all duration-700 ease-in-out"
            >
              <div className="flex flex-row justify-center md:justify-between">
                <img src={Rent} className='hidden lg:block md:w-[200px] md:h-[150px] lg:w-[200px] lg:h-[150px] text-transparent mr-4' alt='' />
                <h4 className="text-center lg:text-left font-light text-black max-w-sm text-base">
                  Our fascinating concept can help you engage with your customer
                  or potential buyer through the play in our machine. And we’d
                  love to provide that fun experience for you.
                </h4>
              </div>
            </div>
          </div>
        </Card>
        {/* <Card
          styleCard='p-6 lg:w-[600px] lg:h-[300px] transition-all duration-400 ease-in-out bg-white rounded-lg border border-gray-200 shadow-md m-10'
        >
          <div className="flex flex-col justify-evenly ">
            <div className="flex justify-center items-center mb-4 ">
              <h1 className="font-bold text-[20px]">Promote Your Brand</h1>
            </div>
            <div
              className="mb-5 transition-all duration-700 ease-in-out w-[480px]"
            >
              <div className="flex flex-row justify-center md:justify-between">
                <Dice className='hidden lg:block flex md:items-center  md:w-[500px] md:h-[400px] lg:w-[500px] lg:h-[500px] lg:mt-[-150px] ' alt='' />
                <h4 className="text-center lg:text-left font-light text-black max-w-sm text-base">
                  If you’re keen to gain awareness for your product or service,
                  we’re more than happy to collaborate with you by including
                  your product or service as our prize.
                </h4>
              </div>
            </div>
          </div>
        </Card> */}
        <Card
          styleCard='p-6  lg:w-[500px] lg:h-[300px] transition-all duration-400 ease-in-out bg-white rounded-lg border border-gray-200 shadow-md m-10'
        >
          <div className="flex flex-col justify-evenly">
            <div className="flex justify-center items-center mb-4 ">
              <h1 className="font-bold text-[20px]">Machine Rent</h1>
            </div>
            <div
              className="mb-3 transition-all duration-700 ease-in-out"
            >
              <div className="flex flex-row justify-center md:justify-between">
                <Dice className='hidden lg:block flex md:items-center  md:w-[500px] md:h-[400px] lg:w-[500px] lg:h-[500px] lg:mt-[-150px] ' alt='' />
                <h4 className="text-center lg:text-left font-light text-black max-w-sm text-base">
                  If you’re keen to gain awareness for your product or service,
                  we’re more than happy to collaborate with you by including
                  your product or service as our prize.
                </h4>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <h3 className="text-center mb-[10px] font-bold text-[20px]">PIC DATA</h3>
      <p className="text-center mr-[10px] ml-[10px] lg:mr-[365px] lg:ml-[365px] text-[15px]">
        Wanna work with us? Contact us {dataInfluencer[0]?.desc} or leave
        us a message
      </p>
      <form
        onSubmit={handleSubmit}
      >
        <div className="px-[20px] lg:px-[50px] 2xl:px-[300px] py-6">
          {/* pic information */}
          <h1 className="font-bold mb-6">PIC Information</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900  lg:mr-[26px]"
              >
                PIC Name
              </label>
              <input
                value={picName}
                required
                onChange={(e) => setPicName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
                placeholder="Full Name"
              />
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 "
              >
                Brand Name
              </label>
              <input
                value={brandName}
                required
                onChange={(e) => setBrandName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
                placeholder="Brand Name"
              />
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 "
              >
                Mobile number
              </label>
              <input
                value={mobile}
                pattern="[0-9]{10,13}"
                required
                onChange={(e) => setMobile(e.target.value)}
                type="tel"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
                placeholder="Mobile Number"
              />
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 mr-10 lg:mr-8"
              >
                Email
              </label>
              <input
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full  lg:ml-4 h-[71px] p-2 italic"
                placeholder="Type Email"
              />
            </div>
          </div>
          {/* PIC Address */}
          <h1 className="font-bold my-6">PIC Address</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex items-center col-span-2">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 mr-2"
              >
                Full Address
              </label>
              <input
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
                placeholder="Type Full Address"
              />
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 mr-6"
              >
                Province
              </label>
              <select
                value={selectProvinsi}
                onChange={(e) => {
                  setIdProvinsi(e.target.value);
                  setSelectProvinsi(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
              >
                <option className="text-gray-900 text-sm">Province</option>
                {getprovinsi
                  ? getprovinsi.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.nama}
                    </option>
                  ))
                  : []}
              </select>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 mr-8"
              >
                City
              </label>
              <select
                value={selectKota}
                required
                onChange={(e) => {
                  setIdKota(e.target.value)
                  setSelectKota(e.target.value)
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full h-[71px] p-2 italic"
              >
                <option className="text-gray-900 text-sm">City</option>
                {getkota
                  ? getkota.map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.nama}
                    </option>
                  ))
                  : []}
              </select>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900 mr-8"
              >
                District
              </label>
              <select
                value={selectKecamatan}
                required
                onChange={(e) => {
                  setSelectKecamatan(e.target.value)
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
              >
                <option className="text-gray-900 text-sm">District</option>
                {getKecamatan
                  ? getKecamatan.map((item) => (
                    <option key={item.id} value={item?.name}>
                      {item?.nama}
                    </option>
                  ))
                  : []}
              </select>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="input-group-1"
                className="hidden md:block text-sm font-medium text-gray-900"
              >
                Post Code
              </label>
              <input
                value={postalcode}
                required
                onChange={(e) => setPostalcode(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full  h-[71px] p-2 italic"
                placeholder="Postal Code"
              />
            </div>
          </div>
          <div className="flex items-center mt-4">
            <label
              htmlFor="input-group-1"
              className="hidden md:block text-sm font-medium text-gray-900 mr-5"
            >
              category
            </label>
            <select
              value={category}
              required
              onChange={(e) => {
                setCategory(e.target.value)
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full lg:ml-4 h-[71px] p-2 italic"
            >
              <option className="text-gray-900 text-sm">category</option>
              <option value='Machine Rent'>Machine Rent</option>
              <option value='Promote Your Brand'>Promote Your Brand</option>
              <option value='Promote Your Brand'>others</option>
            </select>
          </div>
          <div className="flex items-center mt-4">
            <label
              htmlFor="input-group-1"
              className="hidden md:block text-sm font-medium text-gray-900 mr-14"
            >
              Detail
            </label>
            <textarea
              value={detail}

              onChange={(e) => setDetail(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[15px] w-full  h-[71px] p-2 italic"
              placeholder="Detail of Collaboration"
            />
          </div>
          <div className="flex items-center mt-6">
            <label
              htmlFor="input-group-1"
              className="text-sm font-medium text-gray-900 mr-[68px]"
            >
              Additional Files
            </label>
            <input
              type="file"
              onChange={(event) => {
                setImageSelected(event.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="flex justify-center rounded-[50px] w-[150px] h-[71px] mb-10 border border-transparent items-center bg-[#000000] px-8 py-3 text-base font-medium text-white hover:bg-[#000000] opacity-0.5 md:py-4 md:px-10 md:text-lg"
          >
            {loading ? 'sending...' : "SEND"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Collab;
