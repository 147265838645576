import React from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


const Influencer = ({ data }) => {
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <FiChevronLeft className='text-[30px]' /> : <FiChevronRight className='text-[30px]' />
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }
    const dataInfluencer = data.filter((item) => item.id_category.category === 'youtube')
    return (
        <Carousel renderArrow={myArrow} className='px-2 pt-10' itemsToShow={1} pagination={false}>
            {dataInfluencer.map((item) => (
                <iframe
                    title='video'
                    width="1000"
                    height="500"
                    src={`${item.desc}`}
                    frameborder="0"
                    allow="autoplay"
                    allowFullScreen
                />
            ))}
        </Carousel>
    )
}

export default Influencer