import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaTiktok, FaFacebookSquare, FaInstagram } from 'react-icons/fa'
import { BASE_URL } from '../api/BaseUrl'
import Logo from '../assets/Logo.png'
import { NavLink } from 'react-router-dom'


const Footer = ({ data }) => {
    const [loading, setLoading] = useState('')
    const [email, setEmail] = useState('')
    const [wa, setWa] = useState('')
    const [mail, setMail] = useState('')

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true)
            await axios.post(`${BASE_URL}/send-subs`, {
                email: email
            })
            setEmail('')
            setLoading(false)
            alert('email terkirim!')
        } catch (error) {
            setLoading(false)
            alert(error.response.data.message)
        }
    }
    useEffect(() => {
        const infoWA = data.filter((item) => item?.id_category.category === 'contact' && item?.nama === 'whatsapp')
        const infoEmail = data.filter((item) => item?.id_category.category === 'contact' && item?.nama === 'email')
        setWa(infoWA)
        setMail(infoEmail)
    }, [data])
    return (
        <div className='bg-background-footer bg-cover pb-10'>
            <div className='hidden pt-2 md:block flex justify-center '>
                <div className='flex flex-col mb-10 items-center'>
                    <h1 className='font-sans text-[30px] font-bold mt-[30px] mb-[52px] 2xl:text-[40px]'>JOIN OUR NEWS LETTER</h1>
                    <div className='flex items-center'>
                        <form onSubmit={handleSubmit}>
                            <input placeholder='type your email here...' type="email" required className='pl-6 w-[470px] py-[15px] 2xl:w-[570px] 2xl:py-[20px] rounded-lg mr-4' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button type='submit' className='bg-[#2B2B2B] px-[30px] py-[12px] 2xl:px-[50px] 2xl:py-[18px] text-[#FFFFFF] text-[18px] font-sans font-bold rounded-full items-center hover:opacity-75'>
                                {loading ? 'sending...' : 'Subscribe'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div className='flex justify-center lg:justify-start'>
                <img src={Logo} className='pt-4 lg:mb-[38px] lg:ml-[89px]' alt='' />
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-3'>
                <div className='hidden lg:ml-[89px] lg:block'>
                    <p className='mb-[40px] lg:text-[16px] 2xl:text-[25px] '>Mystery Box Indonesia has been in the market since 2019. We have a concept, namely the gift box, and in the Mystery Box Indonesia there are many kinds of attractive prizes along with the Special prizes.</p>
                    <div className='mb-2'>
                        <h3 className='text-[16px] 2xl:text-[25px] font-bold mb-2'>Contact Us</h3>
                        <p className='text-[16px] 2xl:text-[20px]'>Whatsapp: <a href={`https://wa.me/${wa[0]?.desc}`} rel='noreferrer' target='_blank'>{wa[0]?.desc}</a></p>
                        <p className='text-[16px] 2xl:text-[20px]'>Email: {mail[0]?.desc}</p>
                    </div>
                </div>
                <div className='flex flex-col items-center'>
                    <h1 className='text-[28px] lg:text-[16px] 2xl:text-[25px] font-bold mb-4'>Mystery Box Indonesia</h1>
                    <div className='hidden mr-10 2xl:mr-[100px] lg:block'>
                        <NavLink to='/aboutus'>
                            <p className='text-[16px] 2xl:text-[20px] mb-5'>About Us</p>
                        </NavLink>
                        <NavLink to='/collab-with-us'>
                            <p className='text-[16px] 2xl:text-[20px] mb-5'>Collab With Us</p>
                        </NavLink>
                        <NavLink to='/our-location'>
                            <p className='text-[16px] 2xl:text-[20px] mb-5'>Our Locations</p>
                        </NavLink>
                        <NavLink to='/our-box'>
                            <p className='text-[16px] 2xl:text-[20px] mb-5'> What’s In Our Box?</p>
                        </NavLink>
                    </div>
                    <ul className='grid grid-cols-1   flex items-center lg:hidden'>
                        <li>
                            <NavLink to='/aboutus'>
                                <p className='text-center font-bold text-[14px] lg:text-[16px] 2xl:text-[20px] '>About Us</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/our-location'>
                                <p className='text-center font-bold text-[14px] lg:text-[16px] 2xl:text-[20px] '>Our Locations</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/our-box'>
                                <p className=' text-center font-bold text-[14px] lg:text-[16px] 2xl:text-[20px] '> What’s In Our Box?</p>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to='/collab-with-us'>
                                <p className='text-center font-bold text-[14px] lg:text-[16px] 2xl:text-[20px] '>Collab With Us</p>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col items-center lg:items-start lg:ml-20 mt-4 lg:mt-0'>
                    <h1 className='text-[16px] 2xl:text-[25px] font-bold mb-2 lg:mb-4'>Follow Us</h1>
                    <div className='flex items-center mb-[30px]'>
                        <a href='https://instagram.com/mysteryboxidn?igshid=Y2ZmNzg0YzQ=' target='_blank' rel='noreferrer'>
                            <FaInstagram className='mr-[20px] w-[28px] h-[38px] 2xl:w-[38px] 2xl:h-[38px]' />
                        </a>
                        <a href='https://m.facebook.com/mysteryboxidn' target='_blank' rel='noreferrer'>
                            <FaFacebookSquare className='mr-[20px] w-[28px] h-[38px] 2xl:w-[38px] 2xl:h-[38px]' />
                        </a>
                        <a href='https://www.tiktok.com/@mysteryboxidn?_t=8W8rpjqtFVR&_r=1' target='_blank' rel='noreferrer'>
                            <FaTiktok className='w-[28px] h-[30px] 2xl:w-[38px] 2xl:h-[38px]' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer