import React from 'react'

const card = ({ children, styleCard }) => {

    return (
        <div className={styleCard}>
            {children}
        </div>
    )
}

export default card