import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as Logo } from '../assets/Logo.svg'
import { ReactComponent as Vector } from '../assets/Vector.svg';
import Logo1 from '../assets/Logo.png'
import { NavLink } from 'react-router-dom';

const HeadBar = () => {
  return (
    <div className="fixed bg-background-head bg-cover pb-2 w-full sm:pb-2 md:pb-2 sm:w-full lg:w-full lg:max-w-full lg:pb-2 xl:pb-5 z-10">
      <Popover>
        <div className="px-4 pt-4 sm:px-6 lg:px-8">
          <nav className="flex  sm:h-10 md:justify-center" aria-label="Global">
            <div className="flex flex-shrink-2 flex-grow items-center md:flex-grow-0">
              <div className="flex flex-row-reverse w-full items-center justify-between md:w-auto">

                <div className="flex items-center md:hidden">
                  <a href='/'>
                    <img src={Logo1} className="h-10 w-auto sm:h-10" alt='' />
                  </a>

                </div>

                <div className="mr-2 flex items-center md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-black p-2 text-black-400 hover:bg-black-100 hover:text-black-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    <Vector alt="Your Company" className="h-6 w-6" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:ml-16 md:block md:flex md:items-center md:justify-around md:w-full">
              <NavLink
                key='About Us'
                to='/aboutus'
                className="font-bold text-black-500 hover:text-black-900 "
              >
                About Us
              </NavLink>
              <NavLink
                key='Our Location'
                to='/our-location'
                className="font-bold text-black-500 hover:text-black-900 "
              >
                Our Location
              </NavLink>
              <NavLink to="/">
                <Logo
                  alt="Your Company"
                  className="invisible md:visible h-14 w-auto sm:h-12 "
                />
              </NavLink>
              <NavLink
                key="What's In Our Box"
                to='/our-box'
                className="font-bold text-black-500 hover:text-black-900"
              >
                What's In Our Box
              </NavLink>
              <NavLink
                key="Collab With Us"
                to='/collab-with-us'
                className="font-bold text-black-500 hover:text-black-900 "
              >
                Collab With Us
              </NavLink>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <Logo
                    alt="Your Company"
                    className="h-10 w-auto sm:h-10"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="flex flex-col space-y-1 px-2 pt-2 pb-3">
                <NavLink
                  key='About Us'
                  href='/aboutus'
                  className="font-medium text-black-500 hover:text-black-900"
                >
                  About Us
                </NavLink>
                <NavLink
                  key='Our Location'
                  href='/our-location'
                  className="font-medium text-black-500 hover:text-black-900"
                >
                  Our Location
                </NavLink>

                <NavLink
                  key="What's In Our Box"
                  href='/our-box'
                  className="font-medium text-black-500 hover:text-black-900"
                >
                  What's In Our Box
                </NavLink>
                <NavLink
                  key="Collab With Us"
                  href='/collab-with-us'
                  className="font-medium text-black-500 hover:text-black-900"
                >
                  Collab With Us
                </NavLink>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover >
    </div >
  )
};

export default HeadBar;
