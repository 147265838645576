import React, { useEffect, useState } from "react";
import HeadBar from "../components/HeadBar";
import Simulation from "../components/Simulation";
import Information from "../components/Information";
import News from "../components/News";
import LuckyHall from "../components/LuckyHall";
import Footer from "../components/Footer";
import { BsWhatsapp } from "react-icons/bs";
import Influencer from "../components/Influencer";
import Api from "../api/Api";

const Home = () => {
  const [data, setData] = useState([])
  const [wa, setWa] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await Api.get('main-content')
      setData(res.data.data)
    }
    fetchData()
  }, [])


  useEffect(() => {
    const infoWA = data.filter((item) => item?.id_category.category === 'contact' && item?.nama === 'whatsapp')
    setWa(infoWA)
  }, [data])
  return (
    <>
      <div className="bg-background-head bg-cover md:h-[500px] 2xl:h-[650px]">
        <HeadBar />
        <Simulation />
      </div>
      <Information data={data} />
      <News />
      <Influencer data={data} />
      <LuckyHall data={data} />
      <Footer data={data} />
      <a
        className='hidden md:flex fixed justify-center w-[60px] h-[60px] bottom-[60px] right-[40px]  text-center bg-[#25d366] rounded-full'
        href={`https://wa.me/${wa[0]?.desc}`}
        rel='noreferrer'
        target='_blank'
      >
        <BsWhatsapp className='text-[30px] text-slate-50 ml-[1px] mt-[14px]' />
      </a>
    </>
  );
};

export default Home;
