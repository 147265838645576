import React, { useEffect, useState } from 'react'
import Api from '../api/Api'
import { ReactComponent as BG3 } from '../assets/BG-3.svg'
// import { ReactComponent as Dice } from '../assets/Dice.svg'
import DicePng from '../assets/Dice.png'

const AboutUs = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const res = await Api.get('/aboutus-main')
            setData(res.data.data)
        }
        fetch()
    }, [])


    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 lg:grid-flow-row-dense gap-4 place-content-center justify-items-center pt-10 lg:p-20'>
            <BG3 width={400} height={480} className='hidden md:block justify-items-center p-10' />
            <div className='flex flex-col items-center lg:items-start p-10 col-span-2'>
                <h1 className='font-sans text-[30px] font-bold '>ABOUT US</h1>
                {/* <Dice width={200} height={200} className='justify-items-center lg:hidden pt-2' /> */}
                <img src={DicePng} alt='DicePng' width={200} height={200} className='justify-items-center lg:hidden pt-2 mb-[-100px]' />
                <div className='text-center lg:text-left lg:pr-[-10px] '>
                    <div dangerouslySetInnerHTML={{ __html: data[0]?.about }} />
                </div>
                <h3 className='font-sans text-[20px] lg:text-[30px] font-bold'>
                    VISION
                </h3>
                <p className='text-center text-[14px] lg:text-[16px] mb-4'>
                    <div dangerouslySetInnerHTML={{ __html: data[0]?.vision }}></div>
                </p>
                <h3 className='font-sans text-[20px] lg:text-[30px] font-bold '>
                    MISSION
                </h3>
                <div dangerouslySetInnerHTML={{ __html: data[0]?.mission }}></div>
            </div>
        </div >
    )
}

export default AboutUs