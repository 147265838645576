import React, { useEffect, useState } from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { TikTok } from "react-tiktok";


const dataCarousel = [
    {
        url: 'https://www.tiktok.com/@marishachacha/video/7203607216320974107'
    },
    {
        url: 'https://www.tiktok.com/@marishachacha/video/7205065522038181146'
    },
    {
        url: 'https://www.tiktok.com/@ella_yullya/video/7212887293193964826'
    },
    {
        url: 'https://www.tiktok.com/@erwinptr/video/6943527142709939458'
    },
    {
        url: 'https://www.tiktok.com/@vazoahmad/video/7103431950513245467'
    },
    {
        url: 'https://www.tiktok.com/@atikazzz17/video/7150202904866049307'
    },

]


const News = () => {

    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,

    });


    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,

        });
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimenion]);



    const itemsToShow = windowDimenion.winWidth >= 650 ? 3 : 1;

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <FiChevronLeft className='text-[30px]' /> : <FiChevronRight className='text-[30px]' />
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }



    return (
        <>
            <div className='flex justify-center'>
                <div className='flex flex-col mb-4 items-center'>
                    <h1 className='font-sans text-[30px] md:text-[40px] lg:text-[50px] 2xl:text-[60px] font-bold mt-[72px] mb-[32px]'>MBI EXPERIENCE</h1>
                    <hr className='border-[6px] color-[#CFCBE4] border-[#CFCBE4] w-[129px] rounded-md ' />
                </div>
            </div>
            <Carousel
                renderArrow={myArrow}
                className='px-2 flex justify-center'
                itemsToShow={itemsToShow}
                pagination={false}
            >
                {dataCarousel.map((item, i) =>
                    <div key={i} className='rounded-md h-[590px]'
                    >
                        <TikTok url={item.url} />
                    </div>

                )}
            </Carousel>
        </>
    )
}

export default News