import React, { useEffect, useState } from 'react'
import Api from '../api/Api'
import { BASE_URL } from '../api/BaseUrl'

const OurLocation = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        setTimeout(async () => {
            const res = await Api.get('/location')
            const data = await res.data.data
            setLoading(false)
            setData(data)
        }, 3000)
    }, [])

    return (
        <>

            <h1 className='text-center mb-4 font-bold text-[30px] pt-[100px]'>OUR LOCATIONS</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6 md:p-8'>
                {!loading ?
                    data.map((item, i) =>
                        <div className="flex justify-center" key={i}>
                            <div className="rounded-lg shadow-lg bg-white max-w-sm" >
                                <img className="rounded-t-lg md:w-[350px] md:h-[300px] lg:w-[350px] lg:h-[400px]" src={`${BASE_URL}/${item.gambar}`} alt="" />
                                <div className="p-6">
                                    <h5 className="text-gray-900 text-md font-medium mb-2">{item.nama}</h5>
                                    <p className="text-gray-700  mb-4">
                                        {item.detail}
                                    </p>
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="inline-block px-6 py-2.5 bg-[#9587E4] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-300 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                                        Directions
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    [1, 2, 3, 4, 5, 6].map((loading) =>
                        <div className="flex justify-center" key={loading} >
                            <div className="rounded-lg animate-pulse shadow-lg bg-white max-w-sm" >
                                <img className="bg-gray-300 rounded-t-lg md:w-[350px] md:h-[300px] lg:w-[350px] lg:h-[400px]"

                                    alt="" />
                                <div className="p-6">
                                    <h5 className="w-36 bg-gray-300 h-6 rounded-md mb-4 ">{""}</h5>
                                    <p className="w-36 bg-gray-300 h-6 mb-4 rounded-md">
                                        {""}
                                    </p>
                                    <div
                                        className="inline-block w-28 h-8 px-6 py-2.5 bg-gray-300 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-300 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                    >
                                        {""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                }
            </div>
        </>
    )
}

export default OurLocation