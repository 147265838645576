import React, { useEffect, useMemo, useState } from "react";
import Api from "../api/Api";
import IMG from "../assets/image/Texts-BG.svg";
import DicePng from '../assets/Dice.png'



const Simulation = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false)
  const [counterRadius, setCounterRadius] = useState(20)
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await Api.get('/location')
      setData(res.data.data)
    }
    fetchData()
  }, [])

  let link = ''

  const memoCounterRadius = useMemo(() => {
    return counterRadius
  }, [counterRadius])

  const clicked = () => {
    getLocation();
  };

  function distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }

  function getLocation() {

    if (navigator.geolocation) {
      setIsLoading(true)
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      setIsModal(true)
      setMessage("Browser kamu tidak mendukung pengambilan lokasi");
    }
  }

  function showPosition(position) {

    for (var i = 0; i < data.length; i++) {
      // if this location is within 0.1KM of the user, add it to the list
      if (
        distance(
          position.coords.latitude,
          position.coords.longitude,
          data[i].lat,
          data[i].long,
          "K"
        ) <= memoCounterRadius
      ) {
        link = data[i].link
      } else {
        setCounterRadius(counterRadius + 5)
      }
    }


    setTimeout(() => {
      setIsLoading(false);

      if (link !== '') {
        window.open(link);
        link = ''
        setCounterRadius(20)
      }

    }, 2000);
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setMessage("Izin ditolak saat ingin mengambil lokasi");
        setIsModal(true)
        setIsLoading(false)
        break;
      case error.POSITION_UNAVAILABLE:
        setMessage("Informasi lokasi tidak tersedia");
        setIsModal(true)

        break;
      case error.TIMEOUT:
        setMessage("Waktu habis ketika mengambil lokasi");
        setIsModal(true)

        break;
      case error.UNKNOWN_ERROR:
        setMessage("Terjadi kesalahan yang tak terduga, silahkan coba kembali");
        setIsModal(true)

        break;
      default:
        break;
    }
  }


  let circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full'

  return (
    <>
      <div className="flex justify-center w-auto">
        {isModal && (
          alert(message)
        )}
        : {(
          <>
            <img src={IMG} className="absolute top-[80px] md:top-[100px] w-full md:px-10" alt="IMG" />
            <div className="flex items-center z-0">
              <img src={DicePng} className='w-[170px] h-[180px] sm:h-[180px] sm:w-[160px] md:h-[360px] md:w-[310px] 2xl:w-[410px] 2xl:h-[460px] mt-[90px] mb-[30 px] sm:mt-[85px] md:mt-[130px] 2xl:mt-[160px] ' alt='DicePng' />
              <div className="flex flex-col gap-y-1 justify-center items-center absolute right-0 left-0 top-[190px] sm:top-[250px] md:top-[330px] 2xl:top-[480px]">
                <button
                  onClick={()=> {
                    window.open("https://app.mysteryboxindonesia.co.id/", "_blank");
                  }}
                  type="button"
                  className="w-[50px] h-[20px] sm:w-[90px] sm:h-[30px] 2xl:w-[200px] 2xl:h-[70px] md:w-[160px] md:h-[60px] bg-[#2B2B2B] rounded-full text-white text-[7px] sm:text-[10px] md:text-[18px] font-bold hover:bg-[#222222]"
                >Play MBI Online</button>
                <button
                  onClick={clicked}
                  type="button"
                  disabled={isLoading ? true : false}
                  className="w-[50px] h-[20px] sm:w-[90px] sm:h-[30px] 2xl:w-[200px] 2xl:h-[70px] md:w-[160px] md:h-[60px] bg-[#2B2B2B] rounded-full text-white text-[7px] sm:text-[14px] md:text-[22px] font-bold hover:bg-[#222222]"
                >
                  {isLoading ? <div className='flex justify-center '>
                    <div className={`${circleCommonClasses} animate-bounce`}></div>
                    <div
                      className={`${circleCommonClasses}  animate-bounce200`}
                    ></div>
                    <div className={`${circleCommonClasses} animate-bounce400`}></div>
                  </div> : 'Locate Us'}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Simulation;
