import React, { useEffect, useState } from 'react'
import Api from '../api/Api'
import { BASE_URL } from '../api/BaseUrl'

const OurBox = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        setTimeout(async () => {
            const res = await Api.get('/main-content')
            const data = await res.data.data
            setLoading(false)
            setData(data)
        }, 3000)

    }, [])


    return (
        <>
            <h1 className='text-center mb-10 font-bold text-[30px] pt-[100px]'>WHAT’S IN OUR BOX?</h1>
            <div className='grid grid-cols-2 lg:grid-cols-4'>
                {!loading
                    ?

                    data.filter((item) => item.id_category?.category === "product" && item.show === 'true').map((item, i) =>
                        <div className="flex flex-col max-w-sm rounded overflow-hidden items-center" key={i}>
                            <img className="w-[170px] h-[170px] mb-4 " src={`${BASE_URL}/${item.gambar}`} alt="Gambar" />
                            <p className='text-center text-[16px] font-bold mb-10 '>{item.namaBarang}</p>
                        </div>

                    )
                    :
                    [1, 2, 3, 4].map((loading) =>
                        <div className="flex flex-col animate-pulse  max-w-sm rounded overflow-hidden items-center" key={loading}>
                            <img className="w-[170px] h-[170px] mb-4 bg-gray-300"
                                // src={`${BASE_URL}/${item.gambar}`}
                                alt=""
                            />
                            <p className='text-center bg-gray-300 w-28 h-8 text-[16px] font-bold mb-10 '>
                                {""}
                            </p>
                        </div>
                    )
                }
            </div>
            <p className='font-sans text-[14px] font-bold italic flex justify-center mb-4 text-center'>*Content is only for viewing purpose, product could differ from pictures attached</p>
        </>
    )
}

export default OurBox