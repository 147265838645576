import React, { useRef } from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { BASE_URL } from '../api/BaseUrl'

const Information = ({ data }) => {
    const carouselRef = useRef(null);
    let resetTimeout;
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <FiChevronLeft className='bg-[#FFFFFF] border rounded-full text-[28px]' /> : <FiChevronRight className='bg-[#FFFFFF] border rounded-full text-[28px]' />
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }
    const dataBanner = data.filter((item) => item.id_category.category === 'banner')

    return (
        <>
            <Carousel
                ref={carouselRef}
                className='mt-10 p-2'
                renderArrow={myArrow}
                itemsToShow={1}
                showArrows={true}
                enableAutoPlay={true}
                autoPlaySpeed={5000}
                onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    resetTimeout = setTimeout(() => {
                        carouselRef?.current?.goTo(0);
                    }, 4000); // same time
                }}
            >
                {dataBanner.reverse().map((item, i) =>
                    item.desc ?
                        (
                            <a href={item.desc} alt='' rel="noreferrer" target='_blank'>
                                <img key={i} src={`${BASE_URL}/${item.gambar}`} alt='' className='md:w-[1200px] md:h-[450px] 2xl:w-[1500px] 2xl:h-[580px]' />
                            </a>
                        )
                        :
                        (
                            <img key={i} src={`${BASE_URL}/${item.gambar}`} alt='' className='md:w-[1200px] md:h-[450px] 2xl:w-[1500px] 2xl:h-[580px]' />
                        )
                )}
            </Carousel>
        </>
    )
}

export default Information