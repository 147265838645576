import React, { useEffect, useRef, useState } from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { BASE_URL } from '../api/BaseUrl'



const LuckyHall = ({ data }) => {
    const carouselRef = useRef(null);
    let resetTimeout;
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <FiChevronLeft className='text-[30px]' /> : <FiChevronRight className='text-[30px]' />
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,

    });


    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,

        });
    };

    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimension]);

    const itemsToShow = windowDimension.winWidth >= 650 ? 5 : 3;

    const dataLuckyHall = data.filter((item) => item.id_category.category === 'lucky hall')

    return (
        <>
            <div className='flex justify-center'>
                <div className='flex flex-col mb-4 items-center'>
                    <h1 className='font-sans text-[30px] md:text-[40px] lg:text-[50px] 2xl:text-[60px] font-bold mt-[72px] mb-[32px]'>LUCKY HALL</h1>
                    <hr className='border-8 color-[#CFCBE4] border-[#CFCBE4] w-[149px] rounded-md ' />
                </div>
            </div>
            <Carousel
                ref={carouselRef}
                renderArrow={myArrow}
                showArrows={true}
                className='md:px-20'
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                itemsToShow={itemsToShow}
                pagination={false}
                onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    resetTimeout = setTimeout(() => {
                        carouselRef?.current?.goTo(0);
                    }, 4000); // same time
                }}
            >
                {dataLuckyHall.map((item, i) =>
                    <div key={i} className='mb-10 flex flex-col items-center p-4'>
                        <img src={`${BASE_URL}/${item.gambar}`} alt='' className='rounded-full w-[100px] h-[70px] md:w-[100px] md:h-[100px] lg:w-[150px] lg:h-[150px] 2xl:w-[250px] 2xl:h-[250px]' />
                        <h1 className='font-bold text-[12px] lg:text-[18px] 2xl:text-[30px] items-center'>{item.nama}</h1>
                        <p className='italic text-[10px] lg:text-[16px] 2xl:text-[20px] text-center'>{item.namaBarang}</p>
                        <p className='italic text-[10px] lg:text-[16px] text-center'>{item.lokasi}</p>
                    </div>
                )}

            </Carousel>
        </>
    )
}

export default LuckyHall